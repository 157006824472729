import React from "react";
import './tables.css';

const AnchorChainTable = () => {
  return (
      <div className="myTable">
      <table>
          <thead>
              <tr>
                  <td rowSpan={3} style={{width: "150px"}}>Калибр, мм</td>
                  <td colSpan={6}>Нагрузка для типа (категории) цепи, кН, не менее</td>
                  <td rowSpan={3} style={{width: "150px"}}>Теоретическая масса 1 м промежуточной смычки, кг</td>
              </tr>
              <tr>
                  <td colSpan={2}>1</td>
                  <td colSpan={2}>2</td>
                  <td colSpan={2}>3</td>
              </tr>
              <tr>
                  <td>пробная</td>
                  <td>разрушающая</td>
                  <td>пробная</td>
                  <td>разрушающая</td>
                  <td>пробная</td>
                  <td>разрушающая</td>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>11</td>
                  <td>36</td>
                  <td>51</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>3.2</td>
              </tr>
              <tr>
                  <td>12.5</td>
                  <td>46</td>
                  <td>66</td>
                  <td>66</td>
                  <td>92</td>
                  <td>-</td>
                  <td>-</td>
                  <td>3.7</td>
              </tr>
              <tr>
                  <td>14</td>
                  <td>58</td>
                  <td>82</td>
                  <td>82</td>
                  <td>116</td>
                  <td>-</td>
                  <td>-</td>
                  <td>4.3</td>
              </tr>
              <tr>
                  <td>16</td>
                  <td>76</td>
                  <td>107</td>
                  <td>107</td>
                  <td>150</td>
                  <td>-</td>
                  <td>-</td>
                  <td>5.6</td>
              </tr>
              <tr>
                  <td>17.5</td>
                  <td>89</td>
                  <td>127</td>
                  <td>127</td>
                  <td>179</td>
                  <td>-</td>
                  <td>-</td>
                  <td>6.8</td>
              </tr>
              <tr>
                  <td>19</td>
                  <td>105</td>
                  <td>150</td>
                  <td>150</td>
                  <td>211</td>
                  <td>-</td>
                  <td>-</td>
                  <td>8.0</td>
              </tr>
              <tr>
                  <td>20.5</td>
                  <td>123</td>
                  <td>175</td>
                  <td>175</td>
                  <td>244</td>
                  <td>244</td>
                  <td>349</td>
                  <td>9.3</td>
              </tr>
              <tr>
                  <td>22</td>
                  <td>140</td>
                  <td>200</td>
                  <td>200</td>
                  <td>280</td>
                  <td>280</td>
                  <td>401</td>
                  <td>10.7</td>
              </tr>
              <tr>
                  <td>24</td>
                  <td>167</td>
                  <td>237</td>
                  <td>237</td>
                  <td>332</td>
                  <td>332</td>
                  <td>476</td>
                  <td>12.7</td>
              </tr>
              <tr>
                  <td>26</td>
                  <td>194</td>
                  <td>278</td>
                  <td>278</td>
                  <td>389</td>
                  <td>389</td>
                  <td>556</td>
                  <td>14.7</td>
              </tr>
              <tr>
                  <td>28</td>
                  <td>225</td>
                  <td>321</td>
                  <td>321</td>
                  <td>449</td>
                  <td>449</td>
                  <td>642</td>
                  <td>16.5</td>
              </tr>
              <tr>
                  <td>30</td>
                  <td>257</td>
                  <td>368</td>
                  <td>368</td>
                  <td>514</td>
                  <td>514</td>
                  <td>735</td>
                  <td>19.6</td>
              </tr>
              <tr>
                  <td>32</td>
                  <td>291</td>
                  <td>417</td>
                  <td>417</td>
                  <td>583</td>
                  <td>583</td>
                  <td>817</td>
                  <td>22.4</td>
              </tr>
              <tr>
                  <td>34</td>
                  <td>328</td>
                  <td>468</td>
                  <td>468</td>
                  <td>655</td>
                  <td>655</td>
                  <td>937</td>
                  <td>24.6</td>
              </tr>
              <tr>
                  <td>36</td>
                  <td>366</td>
                  <td>523</td>
                  <td>523</td>
                  <td>732</td>
                  <td>732</td>
                  <td>1050</td>
                  <td>28.4</td>
              </tr>
              <tr>
                  <td>38</td>
                  <td>406</td>
                  <td>581</td>
                  <td>581</td>
                  <td>812</td>
                  <td>812</td>
                  <td>1160</td>
                  <td>31.6</td>
              </tr>
              <tr>
                  <td>40</td>
                  <td>448</td>
                  <td>640</td>
                  <td>640</td>
                  <td>896</td>
                  <td>896</td>
                  <td>1280</td>
                  <td>34.5</td>
              </tr>
              <tr>
                  <td>42</td>
                  <td>492</td>
                  <td>703</td>
                  <td>703</td>
                  <td>981</td>
                  <td>981</td>
                  <td>1400</td>
                  <td>38.6</td>
              </tr>
              <tr>
                  <td>44</td>
                  <td>538</td>
                  <td>769</td>
                  <td>769</td>
                  <td>1080</td>
                  <td>1080</td>
                  <td>1540</td>
                  <td>42.2</td>
              </tr>
              <tr>
                  <td>46</td>
                  <td>585</td>
                  <td>837</td>
                  <td>837</td>
                  <td>1170</td>
                  <td>1170</td>
                  <td>1680</td>
                  <td>45.5</td>
              </tr>
              <tr>
                  <td>48</td>
                  <td>635</td>
                  <td>908</td>
                  <td>908</td>
                  <td>1270</td>
                  <td>1270</td>
                  <td>1810</td>
                  <td>49.8</td>
              </tr>
              <tr>
                  <td>50</td>
                  <td>686</td>
                  <td>981</td>
                  <td>981</td>
                  <td>1370</td>
                  <td>1370</td>
                  <td>1960</td>
                  <td>54.0</td>
              </tr>
              <tr>
                  <td>52</td>
                  <td>739</td>
                  <td>1060</td>
                  <td>1060</td>
                  <td>1480</td>
                  <td>1480</td>
                  <td>2110</td>
                  <td>59.1</td>
              </tr>
              <tr>
                  <td>54</td>
                  <td>794</td>
                  <td>1140</td>
                  <td>1140</td>
                  <td>1590</td>
                  <td>1590</td>
                  <td>2270</td>
                  <td>62.5</td>
              </tr>
              <tr>
                  <td>56</td>
                  <td>851</td>
                  <td>1220</td>
                  <td>1220</td>
                  <td>1710</td>
                  <td>1710</td>
                  <td>2430</td>
                  <td>67.5</td>
              </tr>
              <tr>
                  <td>58</td>
                  <td>909</td>
                  <td>1290</td>
                  <td>1290</td>
                  <td>1810</td>
                  <td>1810</td>
                  <td>2600</td>
                  <td>72.8</td>
              </tr>
              <tr>
                  <td>60</td>
                  <td>969</td>
                  <td>1380</td>
                  <td>1380</td>
                  <td>1940</td>
                  <td>1940</td>
                  <td>2770</td>
                  <td>77.1</td>
              </tr>
              <tr>
                  <td>62</td>
                  <td>1030</td>
                  <td>1470</td>
                  <td>1470</td>
                  <td>2060</td>
                  <td>2060</td>
                  <td>2940</td>
                  <td>82.6</td>
              </tr>
              <tr>
                  <td>64</td>
                  <td>1100</td>
                  <td>1560</td>
                  <td>1560</td>
                  <td>2190</td>
                  <td>2190</td>
                  <td>3130</td>
                  <td>88.0</td>
              </tr>
              <tr>
                  <td>66</td>
                  <td>1160</td>
                  <td>1660</td>
                  <td>1660</td>
                  <td>2310</td>
                  <td>2310</td>
                  <td>3300</td>
                  <td>93.0</td>
              </tr>
              <tr>
                  <td>68</td>
                  <td>1230</td>
                  <td>1750</td>
                  <td>1750</td>
                  <td>2450</td>
                  <td>2450</td>
                  <td>3500</td>
                  <td>97.0</td>
              </tr>
              <tr>
                  <td>70</td>
                  <td>1290</td>
                  <td>1840</td>
                  <td>1840</td>
                  <td>2580</td>
                  <td>2580</td>
                  <td>3690</td>
                  <td>103.0</td>
              </tr>
              <tr>
                  <td>73</td>
                  <td>1390</td>
                  <td>1990</td>
                  <td>1990</td>
                  <td>2790</td>
                  <td>2790</td>
                  <td>3990</td>
                  <td>113.0</td>
              </tr>
              <tr>
                  <td>76</td>
                  <td>-</td>
                  <td>-</td>
                  <td>2150</td>
                  <td>3010</td>
                  <td>3010</td>
                  <td>4300</td>
                  <td>123</td>
              </tr>
              <tr>
                  <td>78</td>
                  <td>-</td>
                  <td>-</td>
                  <td>2260</td>
                  <td>3160</td>
                  <td>3160</td>
                  <td>4500</td>
                  <td>130</td>
              </tr>
              <tr>
                  <td>78</td>
                  <td>-</td>
                  <td>-</td>
                  <td>2260</td>
                  <td>3160</td>
                  <td>3160</td>
                  <td>4500</td>
                  <td>130</td>
              </tr>
              <tr>
                  <td>81</td>
                  <td>-</td>
                  <td>-</td>
                  <td>2410</td>
                  <td>3380</td>
                  <td>3380</td>
                  <td>4820</td>
                  <td>145</td>
              </tr>
          </tbody>
      </table>
      </div>
  )
};

export default AnchorChainTable;
import React from "react";
import './tables.css';

const Kenter = () => {
    return (
        <div className="myTable">
        <table>
            <thead>
                <tr>
                    <td>Калибр якорной цепи, d=d3</td>
                    <td>Длина, L3 мм</td>
                    <td>Шаг, P3 мм</td>
                    <td>Ширина, W3 мм</td>
                    <td>Толщина, K, не более</td>
                    <td>Теоритическая масса, кг</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>11</td>
                    <td>66</td>
                    <td>44</td>
                    <td>46</td>
                    <td>17</td>
                    <td>0.28</td>
                </tr>
                <tr>
                    <td>12.5</td>
                    <td>75</td>
                    <td>50</td>
                    <td>53</td>
                    <td>19</td>
                    <td>0.36</td>
                </tr>
                <tr>
                    <td>14</td>
                    <td>84</td>
                    <td>56</td>
                    <td>59</td>
                    <td>21</td>
                    <td>0.45</td>
                </tr>
                <tr>
                    <td>16</td>
                    <td>96</td>
                    <td>64</td>
                    <td>67</td>
                    <td>24</td>
                    <td>0.55</td>
                </tr>
                <tr>
                    <td>17.5</td>
                    <td>105</td>
                    <td>70</td>
                    <td>74</td>
                    <td>27</td>
                    <td>0.7</td>
                </tr>
                <tr>
                    <td>19</td>
                    <td>114</td>
                    <td>76</td>
                    <td>80</td>
                    <td>29</td>
                    <td>0.9</td>
                </tr>
                <tr>
                    <td>20.5</td>
                    <td>123</td>
                    <td>82</td>
                    <td>86</td>
                    <td>31</td>
                    <td>1.1</td>
                </tr>
                <tr>
                    <td>22</td>
                    <td>132</td>
                    <td>88</td>
                    <td>92</td>
                    <td>33</td>
                    <td>1.4</td>
                </tr>
                <tr>
                    <td>26</td>
                    <td>156</td>
                    <td>104</td>
                    <td>109</td>
                    <td>40</td>
                    <td>2.3</td>
                </tr>
                <tr>
                    <td>28</td>
                    <td>168</td>
                    <td>112</td>
                    <td>118</td>
                    <td>43</td>
                    <td>2.8</td>
                </tr>
                <tr>
                    <td>30</td>
                    <td>180</td>
                    <td>120</td>
                    <td>126</td>
                    <td>46</td>
                    <td>3.5</td>
                </tr>
                <tr>
                    <td>32</td>
                    <td>192</td>
                    <td>128</td>
                    <td>134</td>
                    <td>49</td>
                    <td>4.2</td>
                </tr>
                <tr>
                    <td>34</td>
                    <td>204</td>
                    <td>135</td>
                    <td>143</td>
                    <td>52</td>
                    <td>5.0</td>
                </tr>
                <tr>
                    <td>36</td>
                    <td>216</td>
                    <td>144</td>
                    <td>151</td>
                    <td>55</td>
                    <td>6.0</td>
                </tr>
                <tr>
                    <td>38</td>
                    <td>228</td>
                    <td>152</td>
                    <td>160</td>
                    <td>58</td>
                    <td>7.0</td>
                </tr>
                <tr>
                    <td>40</td>
                    <td>240</td>
                    <td>160</td>
                    <td>168</td>
                    <td>61</td>
                    <td>8.0</td>
                </tr>
                <tr>
                    <td>42</td>
                    <td>252</td>
                    <td>168</td>
                    <td>176</td>
                    <td>64</td>
                    <td>9.5</td>
                </tr>
                <tr>
                    <td>44</td>
                    <td>264</td>
                    <td>176</td>
                    <td>185</td>
                    <td>67</td>
                    <td>11.0</td>
                </tr>
                <tr>
                    <td>46</td>
                    <td>276</td>
                    <td>184</td>
                    <td>193</td>
                    <td>70</td>
                    <td>12.5</td>
                </tr>
                <tr>
                    <td>48</td>
                    <td>288</td>
                    <td>1192</td>
                    <td>202</td>
                    <td>73</td>
                    <td>14.0</td>
                </tr>
                <tr>
                    <td>50</td>
                    <td>300</td>
                    <td>200</td>
                    <td>210</td>
                    <td>76</td>
                    <td>17.0</td>
                </tr>
                <tr>
                    <td>52</td>
                    <td>312</td>
                    <td>208</td>
                    <td>218</td>
                    <td>79</td>
                    <td>19.0</td>
                </tr>
                <tr>
                    <td>54</td>
                    <td>324</td>
                    <td>216</td>
                    <td>227</td>
                    <td>82</td>
                    <td>21.0</td>
                </tr>
                <tr>
                    <td>56</td>
                    <td>336</td>
                    <td>224</td>
                    <td>235</td>
                    <td>85</td>
                    <td>23.0</td>
                </tr>
                <tr>
                    <td>58</td>
                    <td>348</td>
                    <td>232</td>
                    <td>244</td>
                    <td>88</td>
                    <td>25.0</td>
                </tr>
                <tr>
                    <td>60</td>
                    <td>360</td>
                    <td>240</td>
                    <td>252</td>
                    <td>91</td>
                    <td>27.5</td>
                </tr>
                <tr>
                    <td>62</td>
                    <td>372</td>
                    <td>248</td>
                    <td>260</td>
                    <td>94</td>
                    <td>30.0</td>
                </tr>
                <tr>
                    <td>64</td>
                    <td>384</td>
                    <td>256</td>
                    <td>269</td>
                    <td>97</td>
                    <td>33.0</td>
                </tr>
                <tr>
                    <td>66</td>
                    <td>396</td>
                    <td>264</td>
                    <td>277</td>
                    <td>100</td>
                    <td>33.0</td>
                </tr>
                <tr>
                    <td>68</td>
                    <td>408</td>
                    <td>272</td>
                    <td>286</td>
                    <td>103</td>
                    <td>39.0</td>
                </tr>
                <tr>
                    <td>70</td>
                    <td>420</td>
                    <td>280</td>
                    <td>294</td>
                    <td>106</td>
                    <td>42.0</td>
                </tr>
                <tr>
                    <td>73</td>
                    <td>438</td>
                    <td>292</td>
                    <td>307</td>
                    <td>111</td>
                    <td>48.0</td>
                </tr>
                <tr>
                    <td>76</td>
                    <td>456</td>
                    <td>304</td>
                    <td>319</td>
                    <td>115</td>
                    <td>54.0</td>
                </tr>
                <tr>
                    <td>78</td>
                    <td>486</td>
                    <td>312</td>
                    <td>328</td>
                    <td>119</td>
                    <td>59.0</td>
                </tr>
                <tr>
                    <td>81</td>
                    <td>486</td>
                    <td>324</td>
                    <td>340</td>
                    <td>123</td>
                    <td>65.0</td>
                </tr>
            </tbody>
        </table>
        </div>
    )
};

export default Kenter;